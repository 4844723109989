<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-snackbar
      v-model="snackbar"
      :multi-line="true"
      color="warning"
      timeout="-1"
      outlined
      absolute
      bottom
      right
      transition="slide-x-reverse-transition"
    >
      {{ error }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="warning" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          <span class="d-none" aria-hidden="true">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <v-card class="mx-auto mb-12">
              <section-header :target="() => self.$refs.sponsorContainer"
                ><v-container>
                  <v-row>
                    <v-col>Sponsor</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-card-text>
                <v-container fluid ref="sponsorContainer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="sponsorId"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :items="sponsorsList"
                          label="Sponsor"
                          :error-messages="errors"
                          prepend-icon="mdi-alpha-s-box"
                          v-model="sponsorId"
                          aria-required="true"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="sponsorContact"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          v-model="sponsorContact"
                          :error-messages="errors"
                          counter
                          maxlength="255"
                          hint="The contact for the Sponsor"
                          label="Sponsor Contact"
                          prepend-icon="mdi-account"
                          required
                          aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                          v-slot="{ errors }"
                          name="rmsSponsorIdentifier"
                          rules="required|min:1|max:255"
                      >
                        <v-text-field
                            outlined
                            v-model="rmsSponsorIdentifier"
                            :error-messages="errors"
                            counter
                            maxlength="255"
                            hint="The RMS id of the sponsor"
                            label="RMS Sponsor ID"
                            required
                            aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>

            <v-card class="mx-auto my-12">
              <section-header :target="() => self.$refs.detailsContainer"
                ><v-container>
                  <v-row>
                    <v-col>Details</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-card-text>
                <v-container fluid ref="detailsContainer">
                  <v-row>
                    <v-col cols="12" sm="4" v-if="!isNew">
                      <dl class="ml-3">
                        <dt>Engagement ID:</dt>
                        <dd>{{ identification }}</dd>
                      </dl>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        ref="name"
                        rules="required|min:3|max:255"
                      >
                        <v-text-field
                          outlined
                          v-model="name"
                          :error-messages="errors"
                          counter
                          maxlength="255"
                          hint="The name of the engagement (i.e. ConquerRSV)"
                          label="Engagement Name"
                          required
                          aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="isActive"
                        rules="required"
                      >
                        <v-radio-group
                          v-model="isActive"
                          row
                          aria-required="true"
                          :error-messages="errors"
                        >
                          <template v-slot:label>
                            <div>Status</div>
                          </template>
                          <fieldset>
                            <legend class="d-none" aria-hidden="true">
                              Status
                            </legend>
                            <v-radio :label="`Active`" :value="true"></v-radio>
                            <v-radio
                              :label="`Inactive`"
                              :value="false"
                              :disabled="checkIfStatusShouldBeDisabled"
                            ></v-radio>
                          </fieldset>
                        </v-radio-group>
                        <div v-if="checkIfStatusShouldBeDisabled">
                          All enagement surveys need to be inactive before
                          changing the engagement status to inactive
                        </div>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="description"
                        rules="required|min:6|max:255"
                      >
                        <v-text-field
                          outlined
                          v-model="description"
                          :error-messages="errors"
                          counter
                          maxlength="255"
                          hint="The Protocol Title"
                          label="Description"
                          required
                          aria-required="true"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-menu
                        v-model="startDate_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="startDate"
                            rules="required|before:@endDate"
                          >
                            <v-text-field
                              v-model="startDate"
                              label="Project Start Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="startDate"
                          @input="startDate_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-menu
                        v-model="endDate_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="endDate"
                            rules="required|after:@startDate"
                          >
                            <v-text-field
                              v-model="endDate"
                              label="Project End Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          @input="end_end_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="radius"
                        rules="required|min_value:1"
                      >
                        <v-text-field
                          outlined
                          v-model="radius"
                          :max="1000"
                          :min="1"
                          :error-messages="errors"
                          prepend-icon="mdi-map-marker-radius"
                          label="Default Radius"
                          type="number"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto my-12">
              <section-header :target="() => self.$refs.mediaContainer"
                ><v-container>
                  <v-row>
                    <v-col>Media</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-card-text>
                <v-container fluid ref="mediaContainer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-menu
                        v-model="mediaStartDate_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="mediaStartDate"
                            rules="required|before:@mediaEndDate"
                          >
                            <v-text-field
                              v-model="mediaLiveDate"
                              label="Media Start Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="mediaLiveDate"
                          @input="startDate_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-menu
                        v-model="mediaEndDate_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="mediaEndDate"
                            rules="required|after:@mediaStartDate"
                          >
                            <v-text-field
                              v-model="mediaEndDate"
                              label="Media End Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="mediaEndDate"
                          @input="startDate_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="g6Url"
                        rules="url"
                      >
                        <v-text-field
                          outlined
                          v-model="g6Url"
                          :error-messages="errors"
                          prepend-icon="mdi-web-check"
                          label="G6 URL"
                          type="url"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto my-12">
              <section-header :target="() => self.$refs.therapiesContainer"
                ><v-container>
                  <v-row>
                    <v-col>Therapies</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-card-text>
                <v-container fluid ref="therapiesContainer">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <validation-provider
                        v-slot="{ errors }"
                        name="therapeuticAreas"
                        rules="required"
                      >
                        <v-select
                          outlined
                          v-model="therapeuticAreas"
                          :items="therapeuticAreasList"
                          chips
                          deletable-chips
                          label="Therapeutic Areas"
                          multiple
                          :error-messages="errors"
                        >
                        </v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-combobox
                        outlined
                        v-model="indications"
                        :items="indicationsList"
                        :search-input.sync="indicationsSearch"
                        hide-selected
                        label="Indications"
                        multiple
                        deletable-chips
                        chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-if="`indicationsSearch !== ''`"
                              >
                                No results matching "<strong>{{
                                  indicationsSearch
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="rareDisease"
                        :label="`Rare Disease`"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="cellGeneTherapy"
                        :label="`Cell/Gene Therapy`"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-checkbox
                        v-model="deiFocus"
                        :label="`DEI Focus`"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card class="mx-auto my-12">
              <section-header :target="() => self.$refs.clinicalTrialsContainer"
                ><v-container>
                  <v-row>
                    <v-col>Clinical Trials</v-col>
                  </v-row>
                </v-container></section-header
              >
              <v-card-text>
                <v-container fluid ref="clinicalTrialsContainer">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="protocolIdentifier"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          counter
                          maxlength="255"
                          v-model="protocolIdentifier"
                          :error-messages="errors"
                          label="Protocol ID"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="rmsProviderId"
                        rules="required|max:255"
                      >
                        <v-select
                          outlined
                          v-model="rmsProviderId"
                          :items="rmsProvidersList"
                          :error-messages="errors"
                          :disabled="checkIfStatusShouldBeDisabled"
                          label="RMS Provider"
                          hint="Which RMS provider is used?"
                          persistent-hint
                          required
                        ></v-select>
                      </validation-provider>
                      <div v-if="checkIfStatusShouldBeDisabled">
                        RMS provider cannot be changed as this engagement has an
                        active survey
                      </div>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="studySubjectIdentifier"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          counter
                          maxlength="255"
                          v-model="studySubjectIdentifier"
                          :error-messages="errors"
                          label="RMS Engagement ID"
                          hint="The RMS ID for the Engagement"
                          type="url"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="phase"
                        rules="required|max:255"
                      >
                        <v-select
                          outlined
                          v-model="phase"
                          :items="phases"
                          :error-messages="errors"
                          label="Phase"
                          hint="Which phases of the study are included?"
                          persistent-hint
                          multiple
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="targetGroups"
                        rules="required"
                      >
                        <v-select
                          outlined
                          v-model="targetGroups"
                          :items="targetGroupsList"
                          chips
                          deletable-chips
                          label="Population"
                          multiple
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="protocolVersion"
                        rules="required|max:255"
                      >
                        <v-text-field
                          outlined
                          counter
                          maxlength="255"
                          v-model="protocolVersion"
                          :error-messages="errors"
                          label="Protocol Version"
                          type="url"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="campaignTypes"
                        rules="required"
                      >
                        <v-select
                          outlined
                          v-model="campaignTypes"
                          :items="campaignTypesList"
                          chips
                          deletable-chips
                          label="Campaign Types"
                          multiple
                          :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="deiTarget"
                        rules="required|min_value:0"
                      >
                        <v-text-field
                          outlined
                          v-model="deiTarget"
                          label="DEI Target"
                          min="0"
                          max="100000"
                          type="number"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        name="targetParticipantCount"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          v-model="targetParticipantCount"
                          label="Randomized Target"
                          type="number"
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="amendmentDate_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="amendmentDate"
                          >
                            <v-text-field
                              v-model="amendmentDate"
                              label="Amendment Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="amendmentDate"
                          @input="amendmentDate_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="first_patient_date_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="firstPatientInDate"
                            rules="required|before:@lastPatientInDate"
                          >
                            <v-text-field
                              v-model="firstPatientInDate"
                              label="First Patient In Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="firstPatientInDate"
                          @input="first_patient_date_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-menu
                        v-model="last_patient_date_menu"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <validation-provider
                            v-slot="{ errors }"
                            name="lastPatientInDate"
                            rules="required|after:@firstPatientInDate"
                          >
                            <v-text-field
                              v-model="lastPatientInDate"
                              label="Last Patient In Date"
                              prepend-icon="mdi-calendar"
                              :error-messages="errors"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </validation-provider>
                        </template>
                        <v-date-picker
                          v-model="lastPatientInDate"
                          @input="last_patient_date_menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-container>
        </v-form>
      </validation-observer>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import { required, min, min_value, max } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import SectionHeader from "@admin/components/SectionHeader.vue";

setInteractionMode("passive");

extend("after", {
  params: ["target"],
  validate(value, { target }) {
    //if before field has not been filled out yet, then bypass this validation
    if (target == "") {
      return true;
    }
    const valueDate = new Date(value);
    const targetDate = new Date(target);

    return valueDate > targetDate;
  },
  message: "This date should come after {_target_}",
});

extend("before", {
  params: ["target"],
  validate(value, { target }) {
    //if after field has not been filled out yet, then bypass this validation
    if (target == "") {
      return true;
    }
    const valueDate = new Date(value);
    const targetDate = new Date(target);

    return valueDate < targetDate;
  },
  message: "This date should come before {_target_}",
});

extend("min", {
  ...min,
  message: "Minimum {length} characters.",
});

extend("min_value", {
  ...min_value,
  message: "Minimum value must be {min} or greater.",
});

extend("max", {
  ...max,
  message: "Maximum {length} characters.",
});

extend("required", {
  ...required,
  message: "This field is required.",
});

extend("url", {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      );
    }
    return false;
  },
  message: "This value must be a valid URL",
});

export default {
  name: "EngagementForm",
  props: {
    id: {
      type: String,
      default: "",
    },
    selectedSponsorId: {
      type: String,
      default: "",
    },
  },
  components: { ValidationObserver, ValidationProvider, SectionHeader },
  data() {
    return {
      engagementId: this.$route.params.engagementId,
      isLoading: true,
      isSubmitting: false,
      originalIdentification: "",
      snackbar: false,
      amendmentDate_menu: false,
      first_patient_date_menu: false,
      last_patient_date_menu: false,
      endDate_menu: false,
      startDate_menu: false,
      mediaEndDate_menu: false,
      mediaStartDate_menu: false,
      indicationsSearch: "",
      self: this,
    };
  },
  watch: {
    indications(current, previous) {
      if (current.length === previous.length) return;
      for (let i = 0; i < current.length; i++) {
        if (typeof current[i] === "string") {
          this.addIndication(current[i]);
          current.pop();
        }
      }
    },
  },
  computed: {
    ...mapFields("engagements", {
      name: "engagement.name",
      description: "engagement.description",
      sponsorId: "engagement.sponsor_id",
      sponsorName: "engagement.sponsorName",
      sponsorContact: "engagement.sponsorContact",
      startDate: "engagement.startDate",
      endDate: "engagement.endDate",
      isActive: "engagement.isActive",
      radius: "engagement.radius",
      mediaLiveDate: "engagement.mediaLiveDate",
      mediaEndDate: "engagement.mediaEndDate",
      g6Url: "engagement.g6Url",
      protocolIdentifier: "engagement.protocolIdentifier",
      studySubjectIdentifier: "engagement.studySubjectIdentifier",
      protocolVersion: "engagement.protocolVersion",
      phase: "engagement.phase",
      campaignTypes: "engagement.campaignTypes",
      amendmentDate: "engagement.amendmentDate",
      firstPatientInDate: "engagement.firstPatientInDate",
      lastPatientInDate: "engagement.lastPatientInDate",
      targetGroups: "engagement.targetGroups",
      targetParticipantCount: "engagement.targetParticipantCount",
      rareDisease: "engagement.rareDisease",
      cellGeneTherapy: "engagement.cellGeneTherapy",
      deiFocus: "engagement.deiFocus",
      deiTarget: "engagement.deiTarget",
      identification: "engagement.identification",
      therapeuticAreas: "engagement.therapeuticAreas",
      indications: "engagement.indications",
      rmsProviderId: "engagement.rms_provider_id",
      rmsSponsorIdentifier: "engagement.rmsSponsorIdentifier",
      rmsProviderName: "engagement.rmsProviderName",
      attachments: [],
    }),
    ...mapState("campaign_types", ["campaignTypesList"]),
    ...mapState("engagements", ["error", "engagement", "phases"]),
    ...mapState("indications", ["indicationsList"]),
    ...mapState("sponsors", ["sponsorsList"]),
    ...mapState("rms_providers", ["rmsProvidersList"]),
    ...mapState("target_groups", ["targetGroupsList"]),
    ...mapState("therapeutic_areas", ["therapeuticAreasList"]),
    ...mapState("users", ["usersList"]),
    ...mapState("surveys", ["surveys", "surveyVersions", "surveysCount"]),

    checkIfStatusShouldBeDisabled(isActive) {
      let disable = false;
      if (isActive) {
        //if active and there are active surveys, then disabled input until all enagement surveys are not active anymore
        if (this.surveys.length > 0) {
          for (let i = 0; i < this.surveys.length; i++) {
            if (this.surveys[i].isActive) {
              disable = true;
              break;
            }
          }
        }
      }
      return disable;
    },
    isNew() {
      return this.engagementId === undefined;
    },
  },
  methods: {
    ...mapActions("campaign_types", ["getCampaignTypesList"]),
    ...mapActions("engagements", [
      "addEngagement",
      "clearEngagement",
      "clearEngagements",
      "editEngagement",
      "getEngagement",
      "identificationIsUnique",
    ]),
    ...mapActions("indications", ["getIndicationsList", "addIndication"]),
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("sponsors", ["getSponsorsList"]),
    ...mapActions("target_groups", ["getTargetGroupsList"]),
    ...mapActions("therapeutic_areas", ["getTherapeuticAreasList"]),
    ...mapActions("rms_providers", ["getRmsProvidersList"]),
    ...mapActions("users", ["getUsersList"]),
    ...mapActions("surveys", ["getSurveysForEngagement"]),
    async submit() {
      const formValid = await this.$refs.observer.validate();

      if (this.isSubmitting || !formValid) return;

      let success = false;
      this.isSubmitting = true;

      if (this.originalIdentification !== this.identification) {
        success = await this.identificationIsUnique(this.identification);
        if (!success) {
          this.$refs.observer.setErrors({
            identification: ["This identification has already been used"],
          });
          this.isSubmitting = false;
          return;
        }
      }
      if (this.isNew) {
        success = await this.addEngagement();
      } else {
        success = await this.editEngagement(this.engagementId);
      }
      if (success !== false) {
        this.clearEngagements(); // force re-pull from db
        const redirect = {
          name: "EngagementView",
          params: { engagementId: success },
        };
        if (this.selectedSponsorId !== "") {
          // If we're adding for a specific sponsor, return to that sponsor's view page
          redirect.name = "SponsorsView";
          redirect.params = {
            id: this.selectedSponsorId,
          };
        }
        await this.$router.push(redirect);
      } else {
        if (this.error === "Engagement name must be unique.") {
          this.$refs.name.applyResult({
            errors: ["This name has already been used"], // array of string errors
            valid: false, // boolean state
            failedRules: {}, // should be empty since this is a manual error.
          });
        }
        this.snackbar = !!this.error;
      }

      this.isSubmitting = false;
    },
  },
  async beforeMount() {
    this.isLoading = true;
    await Promise.all([
      this.getCampaignTypesList(),
      this.getIndicationsList(),
      this.getSponsorsList(),
      this.getTargetGroupsList(),
      this.getTherapeuticAreasList(),
      this.getRmsProvidersList(),
    ]);
    let actionButtons = [];

    if (this.isNew) {
      this.clearEngagement();
      this.setTitleObject("Engagement");
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/engagements",
        },
        index: 0,
      });
      this.setSponsorBreadcrumb({
        id: this.sponsorId,
        name: this.sponsorName,
      });
    } else {
      //get all engagement surveys
      let options = {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: [],
      };
      await this.getSurveysForEngagement({
        engagementId: this.engagementId,
        ...options,
      });

      await this.getEngagement(this.$route.params.engagementId).catch(() => {
        this.$router.push({ name: "NotFound" });
      });
      this.setEngagementBreadcrumb({
        id: this.engagementId,
        name: this.name,
      });
      this.setSponsorBreadcrumb({
        id: this.sponsorId,
        name: this.sponsorName,
      });

      this.originalIdentification = this.identification;
      this.setTitleObject(this.name);
      actionButtons.push({
        component: "CancelButton",
        attributes: {
          to: "/engagements/view/" + this.engagementId,
        },
        index: 0,
      });
    }
    actionButtons.push({
      component: "SaveButton",
      attributes: {
        onSubmit: () => this.submit(),
      },
      index: 1,
    });
    this.setActionButtons(actionButtons);
    if (this.selectedSponsorId !== "") {
      this.sponsorId = this.selectedSponsorId;
    }
    this.setBreadcrumbs();
    this.isLoading = false;
  },
  destroyed() {
    this.clearEngagement();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
fieldset {
  border: 0;
  display: inline-flex;
}
</style>
